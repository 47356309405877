import React, { useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql, WrapPageElementNodeArgs, WrapPageElementBrowserArgs, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { Cursor } from "../components/Cursor"
import { useEventListener } from "../hooks/useEventListener"
import { primaryInput } from "detect-it"
import { windowIfAvailable } from "../utilities/windowIfAvailable"
import { useLocation } from "@reach/router"
import { useDocumentReady } from "../utilities/useDocumentReady"
import { resizeImage } from "../utilities/resizeImage"
import { Logo } from "./Logo"
import { GDPR } from "./gdpr"
import PlainRegularWoff2 from "../fonts/Plain-Regular.woff2"
import usePrevious from "../hooks/usePrevious"
import { Filters } from "../components/Filters"
import { slideSliceHasContent } from "../utilities/slideSliceHasContent"
import fastdom from "fastdom"
import { BrowserInfo } from "detect-browser"
import { FramerNavBar } from "./NavBar.Framer"
import { FramerSideMenu } from "./SideMenu.Framer"
import { m } from "framer-motion"
import { PrismicRichText } from "@prismicio/react"
import { PrismicLink } from "./PrismicLink"

const LayoutQuery = graphql`
  query LayoutQuery {
    allPrismicCategory {
      nodes {
        _previewable
        data {
          name
        }
        id
      }
    }
    prismicSettings {
      _previewable
      data {
        capabilities {
          richText
        }
        clients {
          client {
            document {
              ... on PrismicClient {
              _previewable
                data {
                  name
                  description {
                    richText
                  }
                }
              }
            }
          }
        }
        colophon {
          richText
        }
        contact {
          richText
        }
        description
        formal_name
        homepage {
          document {
            ... on PrismicHome {
              _previewable
              id
              data {
                body {
                  ... on PrismicHomeDataBodySlide {
                    id
                    primary {
                      caption {
                        richText
                      }
                      client {
                        document {
                          ... on PrismicClient {
                            _previewable
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    slice_type
                  }
                  ... on PrismicHomeDataBodyVideoSlide {
                    id
                    primary {
                      caption {
                        richText
                      }
                      client {
                        document {
                          ... on PrismicClient {
                            _previewable
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    slice_type
                  }
                }
              }
            }
          }
        }
        image {
          gatsbyImageData
        }
        title
        overview {
          richText
        }
        work {
          carousel {
            document {
              ... on PrismicCarousel {
                _previewable
                data {
                  autoplay
                  body {
                    ... on PrismicCarouselDataBodySlide {
                      id
                      primary {
                        image {
                          alt
                          gatsbyImageData(placeholder: BLURRED)
                          dimensions {
                            height
                            width
                          }
                        }
                      }
                      slice_type
                    }
                    ... on PrismicCarouselDataBodyVideoSlide {
                      id
                      primary {
                        embed {
                          id
                          provider_name
                          provider_url
                          type
                          title
                          width
                          height
                          embed_url
                          video_id
                          duration
                          account_type
                          author_name
                          author_url
                          description
                          html
                          is_plus
                          prismicId
                          thumbnail_height
                          thumbnail_url
                          thumbnail_url_with_play_button
                          thumbnail_width
                          uri
                          version
                        }
                      }
                      slice_type
                    }
                  }
                  categories {
                    category {
                      document {
                        ... on PrismicCategory {
                          id
                          data {
                            name
                          }
                        }
                      }
                    }
                  }
                  client {
                    document {
                      ... on PrismicClient {
                        id
                        data {
                          name
                          description {
                            richText
                          }
                          url {
                            url
                          }
                        }
                      }
                    }
                  }
                  position
                  size
                  speed
                }
              }
            }
            id
          }
        }
      }
    }
  }
`

const setViewportUnits = () => {
  fastdom.measure(() => {
    const vw = document.documentElement.clientWidth / 100,
      vh = window.innerHeight / 100

    if(
      document.documentElement.style[`--vw`] === `${vw}px`
      && document.documentElement.style[`--vh`] === `${vh}px`
    ) return

    fastdom.mutate(() => {
      document.documentElement.style.setProperty(`--vw`, `${vw}px`)
      document.documentElement.style.setProperty(`--vh`, `${vh}px`)
    })
  })
}

const getUniqueClients = (slides: Array<unknown>) => {
  // Mapping over slide data to pull out just the names 
  const clientNames = slides.map((slide: any) => {
      return slide?.primary?.client?.document?.data?.name
  })?.filter(name => name)
  // Get the unique values in the name list and return its length
  return clientNames?.filter((name, i, a) => {
    return clientNames.indexOf(name) === i
  }).length
}

const countTypes = (carousels: any) => {
  let images = 0,
    videos = 0
  carousels.forEach((node: any) => {
    const project = node?.carousel?.document?.data?.body
    project.map((slide: any) => {
      slideSliceHasContent(slide) && slide
      slide.slice_type === 'video_slide'
        ? videos += 1
        : images += 1
    })
  })
  return {
    images,
    projects: carousels.length,
    videos
  }
}

const carouselNodeHasContent = (node) => 
  node?.carousel?.document?.data?.body?.some(slice => slideSliceHasContent(slice))

const generateCategoryNames = (categories: Array<unknown>): string[] => {
  return categories?.map((node: any) => node?.category?.document?.data?.name)?.filter(c => c)
}

const pluralize = (text: string, count: number): string => {
  return count === 1 ? text : `${text}s`
}

const LayoutContext = React.createContext({
  browser: false,
  filters: [],
  lastHomepageIndex: 0,
  touch: true,
  ready: false,
  reduceMotion: false,
  transitioning: false,
  setClient: (client: any) => { return },
  setClientIndex: (index: number) => { return },
  setSlideIndex: (index: number ) => { return },
  setCarouselLength: (length: number) => { return },
  setMobileFooterVisible: (visible: boolean) => { return },
})

const Layout = ({
  browser,
  children,
  pageContext,
  reduceMotion,
  transitioning,
  // ...rest
}: (WrapPageElementNodeArgs | WrapPageElementBrowserArgs) & { browser: boolean | BrowserInfo, reduceMotion: boolean, transitioning: boolean }) => {
  const staticData = useStaticQuery(LayoutQuery),
    { data: { prismicSettings } } = useMergePrismicPreviewData(staticData),
    // Prevent re-render of Sidemenu
    { title, description, image } = useMemo(() => prismicSettings.data, [prismicSettings]),
    sharingImage = image?.gatsbyImageData ? resizeImage(image?.gatsbyImageData) : {},
    { pathname } = useLocation(),
    previousPathname = usePrevious(pathname),
    ready = useDocumentReady(),
    [clientIndex, setClientIndex] = useState(0),
    [client, setClient] = useState(null),
    [menuIsVisible, setMenuIsVisible] = useState(false),
    [slideIndex, setSlideIndex] = useState(0),
    [filters, setFilters] = useState<string[]>([]),
    [lastHomepageIndex, setLastHomepageIndex] = useState(0),
    touch = primaryInput === `touch`,
    [mobileFooterVisible, setMobileFooterVisible] = useState(false),
    [layoutContext, setLayoutContext] = useState({
      browser,
      filters,
      lastHomepageIndex,
      touch,
      ready,
      reduceMotion,
      transitioning,
      setClient,
      setClientIndex,
      setSlideIndex,
      setMobileFooterVisible,
    })

  const isHome = pathname === `/`,
    isWork = pathname === `/work`

  const carousels = prismicSettings?.data?.work?.filter(node => carouselNodeHasContent(node)),
    carouselCategories = generateCategoryNames(carousels?.flatMap(node => node?.carousel?.document?.data?.categories)),
    carouselCategoryCounts: { [key: string]: number } = {},
    filteredCarousels = filters.length > 0
      ? carousels.filter(node => {
        const carouselCategories = generateCategoryNames(node?.carousel?.document?.data?.categories ?? [])
        return carouselCategories.some((category: string) => filters?.includes(category))
      }) : carousels,
    mediaCounts = countTypes(carousels),
    filteredMediaCounts = countTypes(filteredCarousels)

  carouselCategories.forEach(category => {
    if(carouselCategoryCounts?.[category]) carouselCategoryCounts[category] += 1
    else carouselCategoryCounts[category] = 1
  })

  useEffect(() => {
    setLayoutContext({
      ...layoutContext,
      browser,
      filters,
      lastHomepageIndex,
      touch,
      ready,
      reduceMotion,
      transitioning,
    })
  }, [browser, filters, lastHomepageIndex, touch, ready, reduceMotion, transitioning])

  useEffect(() => {
    setViewportUnits()

    // Store last homepage index
    if(pathname !== previousPathname && previousPathname === `/`) {
      setLastHomepageIndex(slideIndex)
    }
  }, [pathname])

  useEventListener(`resize`, setViewportUnits, windowIfAvailable(), { passive: true })
  
  const workClientNames = prismicSettings?.data?.work?.map(node => node?.carousel?.document?.data?.client?.document?.data?.name ?? undefined)

  const showOnHomeOpacity =
    browser
      ? isHome
        ? `opacity-100`
        : `opacity-0`
      : `opacity-0`,
    showOnWorkOpacity =
      browser
        ? isWork
          ? !mobileFooterVisible
            ? `opacity-100`
            : `opacity-0`
          : `opacity-0`
        : `opacity-0`

  const homepageSlides = prismicSettings?.data?.homepage?.document?.data?.body,
    homepageClientCount = getUniqueClients(homepageSlides),
    homepageImageCount = homepageSlides.filter((slide: any) => slide.slice_type === 'slide').length,
    homepageVideoCount = homepageSlides.filter((slide: any) => slide.slice_type === 'video_slide').length

  return (
    <>
      <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
        <html className={`antialiased ${primaryInput}`} data-client={clientIndex} data-slide={slideIndex} />
        <body className="text-sm" />
        <link rel="preload" href={PlainRegularWoff2} as="font" type="font/woff2" />
        {/* URL */}
        <link rel="canonical" href={`https://javaslehnstudio.com${pathname}`} />
        <meta property="og:url" content={`https://javaslehnstudio.com${pathname}`} />
        {/* Description */}
        {description && <meta name="description" content={description} />}
        {description && <meta property="og:description" content={description} />}
        {description && <meta name="twitter:description" content={description} />}
        {/* Image */}
        {sharingImage?.src !== `` && <meta name="twitter:image" content={sharingImage.src} />}
        {sharingImage?.src !== `` && <meta property="og:image" content={sharingImage.src} />}
        {sharingImage?.width && <meta property="og:image:width" content={`${sharingImage.width}`} />}
        {sharingImage?.height && <meta property="og:image:height" content={`${sharingImage.height}`} />}
        {/* Style */}
        <style>{`
          :root {
            --mobile: 0;
          }

          @media screen and (max-width: 699px) {
            :root {
              --mobile: 1;
            }
          }
        `}</style>
      </Helmet>
      {/* GDPR */}
      {browser ? (
        <GDPR />
      ) : (
        <Helmet>
          <script>{`window["ga-disable-${process.env.GATSBY_GOOGLE_GTAG_ID}"] = true;`}</script>
        </Helmet>
      )}
      {/* {reduceMotion ? (
        <style>{`* { cursor: auto !important; }`}</style>
      ) : ( */}
        <Cursor primaryInput={primaryInput} />
      {/* )} */}
      {/* Intro logo */}
      <m.div
        animate={browser ? { y: `-100%` } : {}}
        transition={{ duration: 1.5 }}
        className="pointer-events-none z-[60] fixed inset-0 w-full h-full flex items-center justify-center bg-black"
        key="splash"
      >
        <Logo className="fill-white w-[566px] max-w-full mx-5" />
      </m.div>
      <FramerNavBar
          client={client}
          clientIndex={clientIndex}
          clientHasCarousel={client?.name && workClientNames.indexOf(client.name) > -1}
          setMenuIsVisible={setMenuIsVisible}
          slideIndex={slideIndex}
        />
      <FramerSideMenu
        {...prismicSettings.data}
        menuIsVisible={menuIsVisible}
        setMenuIsVisible={setMenuIsVisible}
      />
      <LayoutContext.Provider value={layoutContext}>
        {children}
      </LayoutContext.Provider>

      {/* Homepage metadata */}
      <div className={`${showOnHomeOpacity} transition-opacity duration-700`}>
        <div className="pointer-events-none fixed z-10 bottom-0 left-0 m-5 text-black text-opacity-40">
          {/* New York */}
          New York
        </div>
        <div
          className="pointer-events-none hidden sm:flex fixed z-10 bottom-0 left-1/2 transform -translate-x-1/2 w-max my-5 tabular-nums text-black text-opacity-40"
        >
          {/* Media counts */}
          {homepageClientCount > 0 && (<div className="mr-2.5">{pluralize(`${homepageClientCount} client`, homepageClientCount)}</div>)}
          {homepageImageCount > 0 && (<div className="mr-2.5">{pluralize(`${homepageImageCount} image`, homepageImageCount)}</div>)}
          {homepageVideoCount > 0 && (<div>{pluralize(`${homepageVideoCount} video`, homepageVideoCount)}</div>)}
        </div>
        {/* Slide caption */}
        {homepageSlides.map((slide, i) => [slide?.primary?.caption?.richText, i]).map(([richText, i]) => {
          return richText ? (
            <div
              aria-hidden={i === slideIndex ? false : true}
              className={`pointer-events-none hidden sm:block fixed z-10 bottom-0 right-0 max-w-[calc((100vw-162.5px)/2-40px)] m-5 text-black text-opacity-40 ${i === slideIndex ? `opacity-100` : `opacity-0`} transition-opacity duration-700`}
              key={i}
            >
              <PrismicRichText
                field={richText}
                components={{
                  hyperlink: ({ children, node: { data } }) => <PrismicLink className={i === slideIndex ? `pointer-events-auto` : `pointer-events-none`} link={data}>{children}</PrismicLink>,
                  paragraph: ({ children }) => <p className="text-xs text-right">{children}</p>,
                }}
              />
            </div>
          ) : null
        })}
      </div>

      {/* Work metadata */}
      <div className={`${showOnWorkOpacity} transition-opacity duration-700`}>
        {/* Filters */}
        <Filters
          categoryCounts={carouselCategoryCounts}
          filters={filters}
          setFilters={setFilters}
        />
        <div
          className="pointer-events-none fixed z-10 bottom-0 left-1/2 transform -translate-x-1/2 flex w-max my-5 tabular-nums text-black text-opacity-40"
        >
          {/* Media counts */}
          <div className="flex tabular-nums">
            {mediaCounts.projects > 0 && (<div className="px-2">{pluralize(`${`${filteredMediaCounts.projects}`.padStart(`${mediaCounts.projects}`.length, `0`)} project`, filteredMediaCounts.projects)}</div>)}
            {mediaCounts.images > 0 && (<div className="px-2">{pluralize(`${`${filteredMediaCounts.images}`.padStart(`${mediaCounts.images}`.length, `0`)} image`, filteredMediaCounts.images)}</div>)}
            {mediaCounts.videos > 0 && (<div className="px-2">{pluralize(`${`${filteredMediaCounts.videos}`.padStart(`${mediaCounts.videos}`.length, `0`)} video`, filteredMediaCounts.videos)}</div>)}
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
export { Layout, LayoutContext }
